<template>
    <div class="content">
        <div class="banner">
            <div class="bannerImg">
                <transition-group tag="div">
                    <div class="imgWrap active"
                         v-for="(v,i) in banners"
                         :style="{opacity:(i+1)==n?'1':'0'}"
                         v-bind:key="i"
                    >
                        <img :src="v">
                    </div>
                </transition-group>
            </div>
            <ul class="bannerBtn clear-fix">
                <li v-for="(num, i) in 2" :key="i">
                    <a href="javascript:;" :style="{background:num==n?'#0097FF':''}" @click='change(num)' class='aBtn'></a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Product",
        data() {
            return {
                banners:[require('../assets/images/img_02.png'), require('../assets/images/img_03.png')],
                n:1,　　　　　　　
                bFlag:true,
                timer1:'',　　　　
                timer2:'',　　　　
                timer3:'',　　　　
            }
        },
        mounted() {
            this.timer3=setTimeout(this.next,3000);
        },
        methods: {
            next(){　　　　　　　　　　　
                if(this.bFlag){
                    this.bFlag=false;
                    this.clearT();　　　　　　　　　
                    this.n=this.n+1==3?1:this.n+1;
                    this.timeout();
                }
            },
            clearT(){　　　　　　　　　　　　　
                clearTimeout(this.timer1);
                clearTimeout(this.timer2);
                clearTimeout(this.timer3);
            },
            timeout(){
                this.timer2=setTimeout(()=>{
                    this.bFlag=true
                },1000);
                this.timer1=setTimeout(()=>{
                    this.next()
                },3000);
            },
            change(num){
                if(this.bFlag){
                    this.bFlag=false;
                    this.clearT();
                    this.n=num;　　　　　　　         　　
                    this.timeout()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    height: 927px;
    img{
        width: 100%;
    }
}
.banner{
    position: relative;
    width: 100%;
    height: 100%;
}
.bannerImg{
    position: relative;
    overflow: hidden;
    height: 100%;
}
.imgWrap{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 500px;
}
.bannerImg img{
    width: 100%;
}
.bannerImg .active{
    transition:all 1s;
}
.bannerBtn{
    width: 200px;
    position:absolute;
    left:50%;
    margin-left:-100px;
    bottom:78px;
    text-align:center;
}
.bannerBtn li{
    margin: 0 13px;
    width: 34px;
    height: 4px;
    /*border-radius: 50%;*/
    float:left;
    background: rgba(173, 173, 173, 1);
}
.bannerBtn li a{
    display: block;
    width: 34px;
    height: 4px;
    /*border-radius: 50%;*/
    /*margin: 3px;*/
}
.bannerBtn li a.aBtn{
    transition:all .6s ease;
}
</style>